.app-root {
}

::-webkit-scrollbar-track {
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
  }

@media (prefers-color-scheme: dark) {
    /* width */
    ::-webkit-scrollbar-thumb {
        background: rgb(77, 77, 77);
        border-radius: 8px;
    }
    ::-webkit-scrollbar {
        width: 8px;
        background-color: #151515;
    }
}

@media (prefers-color-scheme: light) {
    ::-webkit-scrollbar-thumb {
        background: rgb(175 175 175);
        border-radius: 8px;
    }
    ::-webkit-scrollbar {
        width: 8px;
        background-color: #ffffff;
    }
}

